import React from "react";
import {graphql, Link} from "gatsby";
import styled from "styled-components";
import {PrismicRichText} from "@prismicio/react"
import Seo from "../components/_shared/Seo"
import SliceZone from "../components/SliceZone"

const PostSection = styled.section`
  margin: 30px auto;
  padding: 10px;
  max-width: 920px;
`

// Sort and display the different slice options
const PostSlices = ({slices}) => {
    return slices.map((slice, index) => {
        const res = (() => {
            switch (slice.type) {
                case "text_section":
                    return (
                        <div key={index} className="homepage-slice-wrapper">
                            <PrismicRichText field={slice}/>
                        </div>
                    );

                default:
                    return;
            }
        })();
        return res;
    });
};

// Display the title, date, and content of the Post
const PostBody = ({blogPost}) => {
    const titled = blogPost.title.length !== 0;

    return (
        <div>
            <div className="container post-header">
                <small>{blogPost.date}</small>
                <PrismicRichText field={blogPost.title.richText}/>
            </div>
            {/* Go through the slices of the post and render the appropiate one */}
            <PostSlices slices={blogPost.body}/>

            <div className="back">
                <Link to={`ajankohtaista`}>
                    Takaisin
                </Link>
            </div>
        </div>
    );
};

const Post = ({data}) => {

    if (!data) return null

    const pageContent = data.prismicPost || {};

    return (
        <>
            <Seo title={pageContent.uid}/>
            <SliceZone
                body={pageContent.data.body}
                page={pageContent.uid}
            />
        </>
    );
};

export default Post;

export const Head = () => (
    <Seo title="Ajankohtaista" />
);

export const query = graphql`
  query PostQuery($id: String, $lang: String!) {
    prismicPost(id: { eq: $id }, lang: { eq: $lang }) {
        id
        uid
        data {
          title {
            richText
          }
          date(formatString: "DD.MM.yyyy")
          body {
            ... on PrismicPostDataBodyTextSection {
              id
              slice_type
              primary {
                section_title {
                  richText
                }
                section_text {
                  richText
                }
              }
              items {
                image_title {
                  richText
                }
                image_text {
                  richText
                }
                image {
                  fluid {
                    src
                  }
                }
              }
            }
          }
        }
      }
  }
`
